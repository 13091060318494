import React, { useState } from 'react';
import './Carousel.css'; // Import CSS for styling
import barChart from '../Visualizations/barChart.png';
import Map from '../Visualizations/Map.png'
import timeSeries from '../Visualizations/TimeSeries.png'

const Carousel = () => {
  const [images] = useState([
    {src: barChart, description: 'Bar chart reveals top emitting countries, underlining progress toward SDG 9s goals of emission reduction and sustainable industrialization. Yet, it also signals the necessity for additional efforts in these nations' },
    {src: Map, description: 'Map illustrates emissions levels compared to GDP across regions, shedding light on progress towards Sustainable Development Goal 9s aim of balancing economic growth with environmental sustainability.' },
    {src: timeSeries, description: 'Chart tracks carbon emissions over time, showing progress towards Sustainable Development Goal 9s aim of reducing emissions and promoting sustainable industrialization.'}

    // Add more images as needed
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className="carousel-container">
  
      <div className="image-container">
        <img src={images[currentIndex].src} alt={`Slide ${currentIndex + 1}`} />

        <p>{images[currentIndex].description}</p>
      </div>
      <div class="button-container">
        <button onClick={prevImage}>Previous</button>
        <button onClick={nextImage}>Next</button>
      </div>
      
    </div>
  );
};

export default Carousel;
