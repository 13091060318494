import React from 'react';
import './Insights.css';
import HeaderPhoto from './HeaderPhoto';
import Carousel from './Carousel';
import NewEmissions from '../Images/NewEmissions.png'

function Insights() {
  return (
    <div id = "insights">
      <HeaderPhoto imageSrc={NewEmissions}/>
      <br />
      <p>In order to assess how emissions are changing as a result of greater industry innovation, we have obtained a dataset from <a href="https://ourworldindata.org/sdgs/industry-innovation-infrastructure">Our World in Data</a>. 
        This dataset explores the change in carbon emissions over time. We utilized this data to create various visualizations to track the progress
        our communities and global governments have made towards a more sustainable future.
      </p>

      <br />
      <br />
      <br />

      <h2>Visualizations about the change in the CO2 emissions</h2>
      <br />


      <Carousel />
    </div>
  );
}

export default Insights;
