import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css"; // Import CSS file for NavBar styling

function NavBar() {
    return (
        <div className="topnav">
            <Link to="/" className="active">Home</Link>
            
            <div className="topnav-right">
                <Link to="/insights">Insights</Link>
                <Link to="/CaseStudy">Case Study</Link>
                <Link to="/social">Social</Link>
            </div>
        </div>
    );
}

export default NavBar;

