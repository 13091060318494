import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './Components/NavBar';
import HomePage from './Components/HomePage';
import Insights from './Components/Insights';
import Social from './Components/Social';
import CaseStudy from './Components/CaseStudy';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
      
        <div>
          <Routes>
            <Route path="/" element = {<HomePage />} />
            <Route path="/insights" element ={<Insights />} />
            <Route path="/social" element ={<Social />} />
            <Route path="/CaseStudy" element ={<CaseStudy />} />
          </Routes>
        </div>
        
      </div>
    </Router>
  );
}

export default App;
