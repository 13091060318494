// About.js

import React from 'react';
import './CaseStudy.css'
import HeaderPhoto from './HeaderPhoto';
import BusPhoto from '../Images/Bus.png'
import LowEmissions from '../Visualizations/LowEmissions.png'
import WeeklyAverage from '../Visualizations/WeeklyAverage.png'

const CaseStudy = () => {
  return (
    <div id ='caseStudy'>
      <HeaderPhoto imageSrc={BusPhoto}/>
      <br/>
      <br/>
      <h2>What is the Low Emissions Zone Project?</h2>

      <p>Low Emission Bus Zones, introduced in 2020 as a strategic initiative to combat air pollution in areas outside central London, 
        represent an innovative approach within the transportation industry. These zones strategically target the most severe air quality hotspots
        by deploying cleaner buses along the most heavily polluted routes. By concentrating cleaner buses in these areas, the initiative aims to improve
        air quality and enhance public health by enabling people to breathe cleaner air. <br /><br />

        Transport for London (TfL) has been instrumental in implementing the Low Emission Bus Zones. As part of this forward-thinking initiative, all scheduled 
        TfL buses traversing the 12 designated zones are mandated to meet or surpass the latest Euro VI emissions standards. This stringent regulation underscores 
        the industry's commitment to innovation, ensuring that buses operating within these zones emit lower levels of harmful pollutants and contribute to the overall 
        reduction of emissions in the affected areas. <br /><br />

        The regulations governing emissions within the Low Emission Bus Zones encompass a wide range of pollutants, including nitrous oxides, carbon monoxides, total 
        hydrocarbons, and more. By imposing strict limits on these pollutants, authorities are spearheading innovative measures to significantly decrease the environmental
        impact of public transportation. Through initiatives like the Low Emission Bus Zones, London's transportation industry is at the forefront of pioneering new approaches to 
        sustainability and environmental stewardship, setting a precedent for cities worldwide.</p>
  
          <div className="image-container">

            <h3>12 Proposed Low Emission Zones </h3>
            <img src={LowEmissions} alt='visualisations'/>
            <p>The map displays 12 proposed Low Emission Zones, strategically located across the area to target air pollution hotspots. 
                These zones aim to improve air quality by implementing measures to reduce vehicle emissions and promote sustainable urban development.</p>

            <br></br>
            <br></br>

            <h3>Weekly Averages at New Cross Station</h3>
            <img src={WeeklyAverage} alt='visualisations'/>

            <p>The graph depicts Weekly Averages at New Cross Station, showing trends of various metrics over a typical week. Each day is plotted on the horizontal axis, 
              while the vertical axis displays average values for metrics like foot traffic, temperature, and air quality. 
              The graph offers insights into fluctuations throughout the week, aiding in decision-making for transportation planning and facility management.</p>

            <br></br>
            <br></br>

            <h3>London Air Quality Map</h3>
            <iframe src="https://apps.london.gov.uk/air-quality/" title="Embedded Website" width="1150" height="600"></iframe>

            <p>
              The London Air Quality Map visually represents pollution levels across the city. Using color codes, it indicates variations in pollutants like nitrogen dioxide and particulate matter. 
              This tool helps residents and policymakers monitor air quality, identify pollution hotspots, and make informed decisions to improve environmental health in London.</p>
          </div>

          <br/>
          <br/>
          <br/>

    </div>
  );
};

export default CaseStudy;
