import React from "react";
import "./Video.css"; // Import CSS file for Video styling

function Video({ videoSrc, width, height }) {
  // Function to extract video ID from YouTube link
  // Function to extract video ID from YouTube link
  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#&?]+)/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "Invalid YouTube Link";
    }
  };


  // Construct the embed URL using the extracted video ID
  const embedUrl = `https://www.youtube.com/embed/${getYouTubeID(videoSrc)}`;

  return (
    <div className="video-container">
      <iframe
        width={width}
        height={height}
        src={embedUrl}
        title="YouTube Video Player"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Video;
