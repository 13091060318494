import React from 'react';
import "./Information.css";
import SmartCity from '../Images/SmartCity.png'
import Video from './Video';

function Information() {

  const youtubeLink = "https://www.youtube.com/watch?v=M-iJM02m_Hg";

  return (
    <div id="information">
      <div>
        <h2>What are the United Nations SDGs?</h2>
        <p>Countries worldwide have pledged to achieve the United Nations Sustainable Development Goals (SDGs) by 2030,
            envisioning a more equitable and sustainable future. These goals address a wide range of interconnected challenges, 
            including poverty, hunger, health, education, gender equality, clean water, sanitation, and renewable energy. By fostering
            collaboration and innovation, the SDGs aim to drive transformative change and create a world where every individual 
            can thrive within the limits of our planet's resources.
        </p>
        <br></br>
        <Video videoSrc={youtubeLink} width={900} height={570} />
      
        
        <h3>Advancing Sustainable Development Goal 9: <br/> Industry, Innovation, and Infrastructure</h3>
        <ul>
          <li><strong>Inclusive Industrialization:</strong> Promoting inclusive and sustainable industrialization to drive economic growth and create employment opportunities, particularly in developing countries.</li>
          <li><strong>Infrastructure Development:</strong> Improving infrastructure, including transportation, energy, and information and communication technology (ICT), to support economic development and enhance connectivity.</li>
          <li><strong>Innovation and Technological Advancement:</strong> Encouraging innovation, research, and development to foster technological advancements and enhance productivity, efficiency, and sustainability across industries.</li>
          <li><strong>Access to Sustainable Infrastructure:</strong> Ensuring equal access for all to reliable, resilient, and sustainable infrastructure, including basic services like water, sanitation, and electricity.</li>
          <li><strong>Promoting Sustainable Practices:</strong> Supporting the adoption of sustainable industrial practices and technologies to minimize environmental impact, reduce resource consumption, and mitigate climate change.</li>
          <li><strong>Building Resilience:</strong> Enhancing resilience to natural disasters and other external shocks through robust infrastructure and sustainable industrial practices, contributing to overall community and economic resilience.</li>
          <li><strong>Partnerships for Progress:</strong> Fostering partnerships between governments, businesses, academia, and civil society to mobilize resources, share knowledge, and collaborate on initiatives to advance SDG 9 objectives.</li>
        </ul>
      
        <br/>
        <br/>
        <br/>

        <img src={SmartCity} alt="Upper Section" style={{ width: '900px', height: '570px', objectFit: 'cover' }} />

        <h3>Upgrading Industries & Infrastructures for Sustainability</h3>

        <p>SDG 9 is pivotal in global efforts to combat emissions by focusing on resilient infrastructure, sustainable industrialization, and innovation.
           By aligning with the goal to upgrade infrastructure and retrofit industries by 2030, it aims to make them sustainable through increased resource-use efficiency and greater adoption of clean and environmentally sound technologies and processes. 
           This contributes to reducing reliance on fossil fuels, thus lowering greenhouse gas emissions. <br /><br />

          Moreover, SDG 9 advocates for energy-efficient practices and supports the transition to cleaner production processes. 
          This helps industries and infrastructure minimize their carbon footprint, further aiding in emissions reduction. Additionally, the emphasis on resilience in the face of climate change
          prompts investments in infrastructure capable of withstanding environmental pressures. This, in turn, reduces the need for frequent repairs and replacements, which contribute to emissions.<br /><br />

          Overall, SDG 9 serves as a crucial framework for fostering sustainable development practices. 
          It not only contributes to mitigating climate change but also promotes environmental sustainability on a global scale.</p>

          <br/>
          <br/>
          <br/>

      </div>
      
    </div>
  );
}

export default Information;

