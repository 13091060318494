import React from 'react';
import PostOne from '../SocialPost/PostOne.png'
import PostTwo from '../SocialPost/SoLongLondon.png'
import './Social.css'

const Social = () => {
  return (
    <div id = "SocialPage">

        <div id = "social">
              
          <img id = "p1" src={PostOne} alt="Instsgram Post One" />
          <img src={PostTwo} alt="Instagram Post Two" />
        </div>

    </div>
   
  );
};

export default Social;
