import React from 'react';
import HeaderPhoto from './HeaderPhoto';
import Information from './Information';
import TwentyVision from '../Images/2030Vision.png'

function HomePage() {
  return (
    <div>
      <HeaderPhoto imageSrc={TwentyVision}/>
      <Information />
    </div>
  );
}

export default HomePage;
