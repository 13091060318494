import React from "react";
import "./HeaderPhoto.css"; // Import CSS file for HeaderPhoto styling

function HeaderPhoto({imageSrc}) {
  return (
    <div className="header-photo" style={{ backgroundImage: `url(${imageSrc})` }}>
    </div>
  );
}

export default HeaderPhoto;